import { template as template_0ca4bb78601a40b78db60f03d291300e } from "@ember/template-compiler";
const FKControlMenuContainer = template_0ca4bb78601a40b78db60f03d291300e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
