import { template as template_06a7973e125a4f33a8dc27d71f536042 } from "@ember/template-compiler";
const FKLabel = template_06a7973e125a4f33a8dc27d71f536042(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
